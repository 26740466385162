<template>
  <div>
    <!-- 페이지 제목 -->
    <div v-if="title.secure" style="position: absolute; left: calc(50% - 142px); top: 50px" class="mx-auto">
      <img src="@/assets/reservation/promotion-deco.png" width="300" />
    </div>
    <ReservationTitleLogo v-if="title.secure" :warning="true"> {{ title.name }} <br v-if="title.name" />{{ $t("page.reservation.secret-title") }} </ReservationTitleLogo>
    <TitleLogo v-else :title="$t('page.reservation.package-title')" :warning="false" />

    <v-sheet v-if="!loading" style="padding-bottom: 100px; position: relative">
      <TipTapReader v-if="desc_options.content" class="tiptap_style pa-0" :options="desc_options" :key="descComponentKey + 'des'" style="min-height: 0px" />

      <div>
        <div class="stepTitle px-8 mt-10" style="color: #8d8d8d">
          <span class="font-danjunghae" style="font-size: 34px">STEP.1</span>
          <span class="text-body-2 ml-2">{{ $t("page.reservation.lafia-reservation-step1") }}</span>
        </div>
        <div v-if="!selected_concept" class="d-flex justify-center mt-4" style="margin-bottom: 50px">
          <div class="conceptImageArea" @click="openSelectConcept">
            <span>{{ $t("button.selectConcept") }}</span>
          </div>
        </div>
        <div v-else class="d-flex flex-column align-center justify-center mt-4">
          <v-img
            :lazy-src="require('@/assets/placeholder.jpg')"
            :src="'/upload/concept/thumbnail_image/' + selected_concept.pattern_1_image"
            position="top center"
            aspect-ratio="0.83"
            max-width="180px"
            class="conceptImageAreaBorder"
            @click.stop="openSelectConcept"
            :class="promotionPriceInfo && promotionPriceInfo.conceptId ? 'imageNonSelectable' : 'imageSelectable'"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0 align-center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <span style="color: #8d8d8d; font-size: 14px; margin-top: 2px">[{{ getConceptTitle(selected_concept) }}]</span>
          <!-- <v-btn width="180px" class="conceptTitleBtn rounded-10 px-6 py-5 mt-2" depressed @click="openSelectConcept" :disabled="promotionPriceInfo && promotionPriceInfo.conceptId">
            {{ getConceptTitle(selected_concept) }}
          </v-btn> -->
        </div>
      </div>

      <div class="stepTitle px-8 mt-6" style="color: #8d8d8d">
        <span class="font-danjunghae" style="font-size: 34px">STEP.2</span>
        <span class="text-body-2 ml-2">{{ $t("page.reservation.lafia-reservation-step2") }}</span>
      </div>
      <v-container style="margin-bottom: 100px" class="pa-0 mt-6">
        <div class="dateSelectBox mx-4 mx-md-8">
          <!-- 날짜 선택 -->
          <DateSelector
            :color="$vuetify.theme.currentTheme.primary"
            :width="330"
            class="mt-7 mb-4 mx-auto"
            @dateUpdated="dateUpdated_handler"
            :dateInit="date"
            :key="'DateSelector' + dateSelectComponentKey"
          />
          <!-- 시간 선택 -->
          <div v-if="!timeLoading" class="mx-auto mt-5" style="width: 328px">
            <div v-if="available_time_list.length > 0" class="d-flex justify-start flex-wrap" :key="timeSelectcomponentKey + 'time'">
              <v-btn
                v-for="item in available_time_list"
                :key="item"
                height="36"
                depressed
                class="timeSelectBtn rounded-10 px-3 mb-1 my-1"
                :class="{ enable: selected_time == item }"
                :disabled="already_reserved_time_list.includes(item)"
                @click="!already_reserved_time_list.includes(item) && selected_time != item ? (selected_time = item) : (selected_time = '')"
              >
                {{ item }}
              </v-btn>
            </div>
            <div v-else class="text-center mt-10 mb-8 text-body-1" style="color: #8d8d8d">
              {{ $t("page.reservation.lafia-no-available-time") }}
            </div>
          </div>

          <!-- 로딩 (시간 선택) -->
          <v-progress-circular v-if="timeLoading" indeterminate color="primary" size="40" style="height: 72px" class="d-flex mx-auto mt-8"></v-progress-circular>
        </div>
        <!-- 
        <div class="borderBox px-2 px-md-4 mx-4 mx-md-8 text-caption text-md-body-1 d-flex flex-column justify-center align-center">
          <div>{{ $t("page.reservation.lafia-product-guide-1") }}</div>
          <div>{{ $t("page.reservation.lafia-product-guide-2") }}</div>
          <div class="mt-4 warning--text">{{ $t("page.reservation.lafia-product-guide-3") }}</div>
        </div>
        -->
        <div style="margin-top: 10px">
          <LafiaOptionGroupItem
            :src="{ title: $t('page.reservation.lafia-option-item-c-Title'), subTitle: $t('page.reservation.lafia-option-item-c-subTitle'), price: option3Price }"
            :selected="selectedOption === 'option3'"
            @click="(e) => selectOption('option3', e)"
            @changeCount="changeCount"
          />
          <LafiaOptionItemExt
            :src="{
              title: $t('page.reservation.lafia-option-item-d-Title'),
              subTitle: $t('page.reservation.lafia-option-item-d-subTitle'),
              subTitle1: $t('page.reservation.lafia-option-item-d-subTitle-1'),
              price: option4Price,
            }"
            :discount="option4Discount"
            :origPrice="option4Orig"
            person="2"
            :selected="selectedOption === 'option4'"
            @click="selectOption('option4')"
          />
          <LafiaOptionItemExt
            :src="{
              title: $t('page.reservation.lafia-option-item-e-Title'),
              subTitle: $t('page.reservation.lafia-option-item-e-subTitle'),
              subTitle1: $t('page.reservation.lafia-option-item-e-subTitle-1'),
              price: option5Price,
            }"
            :discount="option5Discount"
            :origPrice="option5Orig"
            person="3"
            :selected="selectedOption === 'option5'"
            @click="selectOption('option5')"
          />
          <LafiaOptionItemExt
            :src="{
              title: $t('page.reservation.lafia-option-item-f-Title'),
              subTitle: $t('page.reservation.lafia-option-item-f-subTitle'),
              subTitle1: $t('page.reservation.lafia-option-item-f-subTitle-1'),
              price: option6Price,
            }"
            :discount="option6Discount"
            :origPrice="option6Orig"
            person="4"
            :selected="selectedOption === 'option6'"
            @click="selectOption('option6')"
          />
          <LafiaOptionItem
            :src="{ title: $t('page.reservation.lafia-option-item-a-Title'), subTitle: $t('page.reservation.lafia-option-item-a-subTitle'), price: option1Price }"
            :selected="selectedOption === 'option1'"
            @click="selectOption('option1')"
          />
          <!-- <LafiaOptionItem
            :src="{ title: $t('page.reservation.lafia-option-item-b-Title'), subTitle: $t('page.reservation.lafia-option-item-b-subTitle'), price: option2Price }"
            :selected="selectedOption === 'option2'"
            @click="selectOption('option2')"
          /> -->
        </div>
      </v-container>

      <!-- 예약하기 하단 -->
      <v-sheet style="width: 100%; max-width: 580px; position: fixed; bottom: 72px; padding-bottom: 20px; border-top: 1px solid #e0e0e0; z-index: 15; background-color: white">
        <!-- 최종가격 -->
        <p class="text-end font-danjunghae ml-1 px-6 mt-2 mb-0" style="font-size: 40px; color: #5b4a62">
          <span class="mr-2 text-body-1 grey--text text--darken-2" style="vertical-align: 10%">{{ $t("page.reservation.total-reserv-persons", { person: this.persons }) }}</span>
          {{ $toComma(computed_total_price) }}<span class="text-body-1 ml-1 grey--text text--darken-2">{{ $t("common.krw") }}</span>
        </p>

        <!-- 쿠폰, 예약하기 -->
        <div class="d-flex px-6 pb-4 justify-center">
          <v-btn
            large
            width="50%"
            color="primary"
            style="font-size: 25px"
            class="rounded-10 pb-1 ml-4 darken-1"
            depressed
            :disabled="selected_time == '' || !persons || !selected_concept"
            @click="showRefundPolicyDialog()"
            :loading="pay_loading"
          >
            {{ $t("button.pay") }}
          </v-btn>
        </div>
      </v-sheet>
    </v-sheet>
    <v-sheet v-else class="text-center mt-14">
      <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
    </v-sheet>
    <ConceptSelectDialog
      :visible="show_concept_select"
      @close="show_concept_select = false"
      @input="(e) => (show_concept_select = e)"
      :key="'concept' + show_concept_select_componentKey"
      @apply="(item) => (selected_concept = item)"
    />
    <NormalWarningDialog :msg="warning_dialog_messsage" @input="warningDialogInputEvent" @confirm="confirmDialog" :visible="show_warning_dialog" :key="'normal' + show_warning_dialog_componentKey" />

    <!-- <RefundPolicyGuideDialog
      @agree="agreeRefundPolicy"
      @cancel="show_refund_policy = false"
      @input="(e) => (show_refund_policy = e)"
      :visible="show_refund_policy"
      :key="refund_policy_dialog_componentKey"
    /> -->
    <CautionMesageDialog
      @agree="agreeRefundPolicy"
      @cancel="show_refund_policy = false"
      @input="(e) => (show_refund_policy = e)"
      :visible="show_refund_policy"
      :key="refund_policy_dialog_componentKey"
    />
  </div>
</template>
<script>
import { computed } from "vue"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import TitleLogo from "@/views/components/TitleLogo"
import DateSelector from "@/components/DateSelector"
import ReservationTitleLogo from "@/views/components/ReservationTitleLogo"
import LafiaOptionItem from "./components/LafiaOptionItem"
import LafiaOptionItemExt from "./components/LafiaOptionItemExt"
import LafiaOptionGroupItem from "./components/LafiaOptionGroupItem"
import ConceptSelectDialog from "@/views/components/ConceptSelectDialog"
import TipTapReader from "@/components/tiptap/Reader"
import { OperationTimeList, Holidays } from "@/constant"
import NormalWarningDialog from "@/views/components/NormalWarningDialog"
// import RefundPolicyGuideDialog from "@/views/components/RefundPolicyGuideDialog"
import payletterService from "@/api/payletter-service"
import CautionMesageDialog from "@/views/components/CautionMesageDialog"
export default {
  components: {
    TitleLogo,
    DateSelector,
    ReservationTitleLogo,
    LafiaOptionItem,
    LafiaOptionItemExt,
    LafiaOptionGroupItem,
    ConceptSelectDialog,
    TipTapReader,
    NormalWarningDialog,
    // RefundPolicyGuideDialog,
    CautionMesageDialog,
  },

  provide() {
    return {
      promotion_info: computed(() => this.promotionPriceInfo),
      concepts: computed(() => this.selectableConceptList),
    }
  },
  data: () => ({
    loading: true,
    promotionPriceInfo: null,

    date: null,
    dateSelectComponentKey: 0,

    timeLoading: false,
    time_option_list: OperationTimeList,
    available_time_list: [],

    selected_time: "",
    timeSelectcomponentKey: 0,

    already_reserved_time_list: [],

    selectedOption: "option1",

    descComponentKey: 0, //desc html
    //TipTap
    desc_options: {
      content: "",
      editable: false,
      supportImage: true,
      supportVideo: true,
    },

    tossPayments: null,
    reservation_dogin: false,

    //컨셉 선택
    show_concept_select_componentKey: 0,
    show_concept_select: false,
    selected_concept: null,

    persons: 1,

    //결제 진행중
    pay_loading: false,

    //payletter window
    payletterWindow: null,
    payletterWindowEventListener: null,

    isFirstSkipDateWarningDialog: false,
    //warning dialog
    show_warning_dialog: false,
    warning_dialog_messsage: "",
    show_warning_dialog_componentKey: 0,

    show_refund_policy: false,
    refund_policy_dialog_componentKey: 0,

    conceptList: [],
  }),

  watch: {
    lang() {
      console.log("watch lang")
      this.setDescription()
    },

    // date: {
    //   handler(newVal, oldVal) {
    //     // 날짜 변경시 promotion 정보 업데이트 - 날짜 유효성 여부 체크
    //     console.log("date handler", newVal.format("MM/DD"), oldVal && oldVal.format("MM/DD"))
    //     if (this.promotionPriceInfo) {
    //       if (newVal && !oldVal) {
    //         this.checkPromotionDate(this.promotionPriceInfo, true)
    //       } else if (newVal && oldVal && !newVal.isSame(oldVal, "day")) {
    //         this.checkPromotionDate(this.promotionPriceInfo)
    //       }
    //     }
    //   },
    //   immediate: false,
    // },
  },

  async mounted() {
    this.tossInit()

    try {
      if (!this.operation_time) {
        const time_info = await this.loadOperationTime()
        if (!time_info) {
          alert(this.$t("alert.error_page"))
          this.$router.go(-1)
        }
      }

      this.conceptList = await this.loadConceptsAll()

      const res = await this.getPromotionPrice({ promotionCode: this.getPromotionCode })

      if (this.$route.query.conceptId) {
        this.selected_concept = this.conceptList.find((e) => e.id == this.$route.query.conceptId)
      }

      let _promotionInfo = res.promotionPrice
      if (_promotionInfo) {
        if (_promotionInfo.openAt && _promotionInfo.closeAt) {
          const openAt = this.dayjs(_promotionInfo.openAt)
          const closeAt = this.dayjs(_promotionInfo.closeAt)
          if (openAt && closeAt) {
            if (!this.dayjs().isBetween(openAt, closeAt, "day", "[]")) {
              alert(this.$t("alert.error_invalid_product_page"))
              this.$router.replace("/")
              return
            }
          }
        }

        //선택한 프로모션이 특정 컨셉용인지 확인
        if (_promotionInfo.conceptId) {
          this.selected_concept = this.conceptList.find((e) => e.id === _promotionInfo.conceptId)
        }
      }
      this.promotionPriceInfo = _promotionInfo
    } catch (e) {
      console.log(e)
      alert(this.$t("alert.error_page"))
      this.$router.go(-1)
    } finally {
      this.setDescription()
      this.loading = false
    }
  },
  computed: {
    ...mapState(["default_price", "lafia_user", "lang", "operation_time", "isSupportAbroadPay"]),
    ...mapGetters(["isKRW"]),

    getMaxAvailableRoom() {
      return 1 // 2 -> 3 -> 1
    },
    getPromotionCode() {
      return this.$route.query.event
    },
    title() {
      if (this.promotionPriceInfo) {
        return { name: this.getTitle(this.promotionPriceInfo), secure: true }
      } else {
        return { name: "", secure: false }
      }
    },

    option1Price() {
      return this.promotionPriceInfo ? this.promotionPriceInfo.option1Price : this.default_price.option1Price
    },
    option2Price() {
      return this.promotionPriceInfo ? this.promotionPriceInfo.option2Price : this.default_price.option2Price
    },
    option3Price() {
      return this.promotionPriceInfo ? this.promotionPriceInfo.option3Price : this.default_price.option3Price
    },
    option4Price() {
      return this.promotionPriceInfo ? this.promotionPriceInfo.option4Price : this.default_price.option4Price
    },
    option4Orig() {
      let orig = this.option3Price * 2 + this.option1Price * 2
      return orig
    },
    option4Discount() {
      let option4Price = this.option4Price
      let orig = this.option4Orig
      return Math.round(((orig - option4Price) * 100) / orig)
    },
    option5Orig() {
      let orig = this.option3Price * 3 + this.option1Price * 3
      return orig
    },
    option5Discount() {
      let option5Price = this.option5Price
      let orig = this.option5Orig
      return Math.round(((orig - option5Price) * 100) / orig)
    },
    option5Price() {
      return this.promotionPriceInfo ? this.promotionPriceInfo.option5Price : this.default_price.option5Price
    },

    option6Orig() {
      let orig = this.option3Price * 4 + this.option1Price * 4
      return orig
    },
    option6Discount() {
      let option6Price = this.option6Price
      let orig = this.option6Orig
      return Math.round(((orig - option6Price) * 100) / orig)
    },
    option6Price() {
      return this.promotionPriceInfo ? this.promotionPriceInfo.option6Price : this.default_price.option6Price
    },

    computed_original_price() {
      let price = this.default_price.option1Price
      switch (this.selectedOption) {
        case "option2":
          price = this.default_price.option2Price
          break
        case "option3":
          price = this.default_price.option3Price
          break
        case "option4":
          price = this.default_price.option4Price
          break
        case "option5":
          price = this.default_price.option5Price
          break
        case "option6":
          price = this.default_price.option6Price
          break
        default: //option1
          break
      }
      return Math.max(price, 0)
    },

    // 최종 가격 계산
    computed_total_price() {
      let price = this.promotionPriceInfo ? this.promotionPriceInfo.option1Price : this.default_price.option1Price
      switch (this.selectedOption) {
        case "option2":
          price = this.promotionPriceInfo ? this.promotionPriceInfo.option2Price : this.default_price.option2Price
          break
        case "option3":
          price = (this.promotionPriceInfo ? this.promotionPriceInfo.option3Price : this.default_price.option3Price) * this.persons
          break
        case "option4":
          price = this.promotionPriceInfo ? this.promotionPriceInfo.option4Price : this.default_price.option4Price
          break
        case "option5":
          price = this.promotionPriceInfo ? this.promotionPriceInfo.option5Price : this.default_price.option5Price
          break
        case "option6":
          price = this.promotionPriceInfo ? this.promotionPriceInfo.option6Price : this.default_price.option6Price
          break
        default: //option1
          break
      }
      return Math.max(price, 0)
    },

    selectableConceptList() {
      return this.conceptList.filter((e) => e.status === "노출")
    },
  },

  methods: {
    ...mapActions(["getPromotionPrice", "tossInit", "loadOperationTime", "loadConceptsAll", "cancelPayStatusUpdate"]),

    getTitle(promotionPriceInfo) {
      let name = promotionPriceInfo && promotionPriceInfo.name
      return name ? `${name}` : ""
    },

    async dateUpdated_handler(date) {
      console.log("dateUpdated_handler")
      this.date = date.startOf("day")
      this.selectOption("option3", 2) // 기본 선택 이 option1 -> option3 인원2

      // 해당 날짜의 영업 시간 확인
      this.available_time_list = this.getWorkDayTime(this.date)
      //날짜가 바뀐 경우 정보 초기화
      await this.checkOpenTime()
      if (this.promotionPriceInfo) {
        this.checkPromotionDate(this.promotionPriceInfo)
      }
    },
    // 선택된 날짜의 닫힌 시간 확인하기
    async checkOpenTime() {
      this.timeLoading = true
      // 선택한 시간 초기화
      this.selected_time = ""
      this.already_reserved_time_list = []

      let defaultExpireTime = null
      if (this.default_price && this.default_price.endAt) {
        defaultExpireTime = this.dayjs(this.default_price.endAt)
      }

      let autoEndAt = this.dayjs().add(3, "month").endOf("month")

      const diffMonth = this.date.startOf("month").diff(this.dayjs().startOf("month"), "month")
      // 지난 날짜의 경우
      if (this.date.isBefore(this.dayjs(), "date")) {
        // 전부 닫기
        this.already_reserved_time_list = this.time_option_list
        this.timeLoading = false
      }
      // 기본 가격에 따른 endAt 날짜에 따라 open 여부 결정
      else if (defaultExpireTime && this.date.isAfter(defaultExpireTime, "date")) {
        // 전부 닫기
        this.already_reserved_time_list = this.time_option_list
        this.timeLoading = false
      } else if (this.date.isAfter(autoEndAt, "date")) {
        // 전부 닫기
        this.already_reserved_time_list = this.time_option_list
        this.timeLoading = false
      } else if ((this.date.day() === 0 || this.date.day() === 6) && diffMonth > 2) {
        // 주말은 다음 달까지만 예약이 가능하고 그 이후는 전부 닫기 // 다음달(1) -> 다다음달(2)
        this.already_reserved_time_list = this.time_option_list
        this.timeLoading = false
      } else {
        console.log("checkOpenTime check date: ", this.date.format("YYYY-MM-DD"))
        this.already_reserved_time_list = []

        //연속 시간대 확인.
        try {
          await this.checkContinuouslyReservation()
        } catch (e) {
          console.log(e)
        }

        // 오늘 날짜일시 예약시간이 지났는지 확인
        this.TodayTimePassCheck()
        this.WorkTimeCheck(this.date)

        try {
          await this.todayTimeAvailableRoomCheck()
        } catch (e) {
          alert(this.$t("alert.error_page"))
          this.$router.go(-1)
        } finally {
          this.timeLoading = false
        }
      }
    },

    openSelectConcept() {
      if (this.promotionPriceInfo && this.promotionPriceInfo.conceptId) {
        return
      }
      this.show_concept_select = true
      this.show_concept_select_componentKey++
    },

    selectOption(value, count) {
      console.log("selectOption count", value, count)
      this.selectedOption = value
      switch (value) {
        case "option1":
        case "option2":
        case "option4":
        case "option5":
        case "option6":
          this.persons = 1
          break
        case "option3":
          this.persons = count
          break
      }
    },

    changeCount(value) {
      console.log("changeCount", value)
      if (this.selectedOption === "option3") {
        this.persons = value
      }
    },

    getConceptTitle(concept) {
      switch (this.lang) {
        case "en":
          return concept.title_en || concept.title
        case "jp":
          return concept.title_jp || concept.title
        default:
          return concept.title
      }
    },

    getWorkDayTime(date) {
      if (!this.operation_time) {
        return []
      }

      const weekData = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
      const op_str = this.operation_time[weekData[date.day()]]

      // 특정 날짜(2025-05-05)에 대한 특별 시간 설정
      if (date.format("YYYY-MM-DD") === "2025-05-05") {
        return [
          "12:15",
          "12:30",
          "13:00",
          "13:15",
          "13:30",
          "14:00",
          "14:15",
          "14:30",
          "15:00",
          "15:15",
          "15:30",
          "17:00",
          "17:15",
          "17:30",
          "18:00",
          "18:15",
          "18:30",
          "19:00",
          "19:15",
          "19:30",
          "20:00",
          "20:15",
          "20:30",
        ]
      }

      return op_str.length
        ? op_str
            .split(",")
            .map((e) => e.trim())
            .filter((e) => e.length > 0)
            .sort()
        : []
    },

    WorkTimeCheck(date) {
      const workTimes = this.getWorkDayTime(date)

      if (this.isHoliday(date)) {
        this.already_reserved_time_list = this.time_option_list
      } else if (workTimes.length) {
        const offTime = this.time_option_list.filter((item) => !workTimes.includes(item))
        this.already_reserved_time_list.push(...offTime)
      } else {
        this.already_reserved_time_list = this.time_option_list
      }
    },

    isHoliday(date) {
      return Holidays.includes(date.format("YYYY-MM-DD"))
    },

    // 오늘 날짜일시 예약시간이 지났는지 확인
    TodayTimePassCheck() {
      // 오늘 날짜일 경우
      if (this.dayjs().isSame(this.date, "date")) {
        this.time_option_list.forEach((time) => {
          // 30 분 이전 까지만 예약 가능 // 30 -> 1분으로 수정
          const now = this.dayjs().add(1, "minute")

          let timeToCheck = this.dayjs()
            .hour(parseInt(time.split(":")[0]))
            .minute(parseInt(time.split(":")[1]))

          // 현재 시각이 지났을 경우
          if (timeToCheck.isBefore(now)) {
            this.already_reserved_time_list.push(time)
          }
        })
      }
    },
    // 오늘 날짜 예약 Full 시간대 check
    async todayTimeAvailableRoomCheck() {
      // 결제 전 기예약인원 재확인
      const reservedList = await this.$http
        .post("/api/reservation/select/specific/time/lafia/check", {
          params: {
            year: this.date.year(),
            month: this.date.month() + 1,
            day: this.date.date(),
          },
        })
        .then((res) => {
          return res.data.map((e) => ({
            time: e.time,
            count: Number(e.count),
          }))
        })

      for (let item of reservedList) {
        if (item.count >= this.getMaxAvailableRoom) {
          this.already_reserved_time_list.push(item.time)
        }
      }
    },

    async checkContinuouslyReservation() {
      const reservedList = await this.$http.post("/api/reservation/select/specific/time/lafia/check", {
        params: {
          year: this.date.year(),
          month: this.date.month() + 1,
          day: this.date.date(),
        },
      })
      //console.log(reservedList)
      const myReservedLsit = reservedList.data.filter((e) => e.user_id === this.lafia_user.user_id)
      //console.log(myReservedLsit)

      // 오늘 날짜의 예약 시간대 리스트
      if (myReservedLsit.length > 0) {
        const timeList = myReservedLsit.map((e) => e.time)
        //console.log(timeList)

        timeList.forEach((reservedTime) => {
          const baseTime = this.dayjs(reservedTime, "HH:mm")

          this.time_option_list.forEach((operationTime) => {
            const opTime = this.dayjs(operationTime, "HH:mm")
            const diff = opTime.diff(baseTime, "minute")

            if (Math.abs(diff) <= 15) {
              // 예약된 시간과 15분 이내의 시간은 예약 불가능
              if (!this.already_reserved_time_list.includes(operationTime)) {
                this.already_reserved_time_list.push(operationTime)
              }
            }
          })
        })
      }
    },

    reservation: _.debounce(async function () {
      let orderId = Math.floor(Math.random() * 1000000000)

      if (!this.lafia_user.is_logined) {
        if (this.getPromotionCode) {
          this.$store.commit("ADD_PROMOTION_CODE", this.getPromotionCode)
        }
        alert(this.$t("alert.error_need_authentication"))
        let loginPath = "/auth/login"
        if (this.getPromotionCode) {
          loginPath = loginPath + `?event=${this.getPromotionCode}`
        }
        this.$router.push(loginPath)
        return
      }

      try {
        // 결제 진행중
        this.pay_loading = true

        // 오늘 날짜일시 예약시간이 지났는지 재확인
        await this.TodayTimePassCheck()

        // 중복 예약 체크
        const myReservedList = await this.$http.post("/api/reservation/select/specific/time/lafia/check/id", {
          params: {
            year: this.date.year(),
            month: this.date.month() + 1,
            day: this.date.date(),
            time: this.selected_time,
            user_id: this.lafia_user.user_id,
          },
        })

        if (myReservedList.data && myReservedList.data[0].count > 0) {
          alert(this.$t("alert.error_duplicate_reservation"))
          this.pay_loading = false // 결제 진행 중 취소
          this.$router.go(0)
          return
        }

        // 예약 전 다시한번 현재 시간대에 공간이 있는지 체크
        await this.todayTimeAvailableRoomCheck()

        // 시간 지났을 경우 종료
        if (this.already_reserved_time_list.includes(this.selected_time)) {
          alert("예약할 시각은 예약이 모두 완료되었습니다.")
          this.pay_loading = false // 결제 진행 중 취소
          this.$router.go(0)
          return
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.pay_loading = false // 결제 진행 중 취소
      }

      let orderList = [
        {
          productName: "라피아 이용권",
          orderNum: this.persons,
          price: this.computed_original_price,
          totalPrice: this.persons * this.computed_original_price,
          finalPrice: Math.max(this.computed_total_price, 0),
          discountCode: this.getPromotionCode,
        },
      ]

      const orderRes = await this.$http.post("/api/order/v2/order", {
        orderId: orderId.toString(),
        userId: this.lafia_user.user_id,
        orderDetailList: orderList,
        memo: this.selectedOption,
        type: "온라인이용권",
      })

      let params = {
        order_id: orderRes.data.id,
        user_id: this.lafia_user.user_id,
        category: "라피아캐슬",
        maker: null,
        concept: this.selected_concept.title,
        year: this.date.year(),
        month: this.date.month() + 1,
        day: this.date.date(),
        time: this.selected_time,
        cloth: this.persons,
        clothPrice: this.computed_total_price,
        uncloth: 0,
        unclothPrice: 0,
        promotionCode: this.getPromotionCode,
      }

      // 시간예약 입력하기
      await this.$http.post("/api/reservation/v2/reserve", {
        ...params,
      })

      if (window.location.host.startsWith("localhost") && window.confirm("테스트 결제 하기")) {
        // 모든 작업이 완료된 후 토스 결제
        this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=test&amount=" + this.computed_total_price)
      } else if (/*this.computed_total_price_original > 0 &&*/ this.computed_total_price === 0) {
        this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=free&amount=" + this.computed_total_price)
      } else {
        let orderName = this.getOrderName()

        if (this.isSupportAbroadPay && !this.isKRW) {
          const { online_url, mobile_url } = await payletterService.requestInternationalPay({
            storeorderno: orderId.toString(),
            amount: this.computed_total_price,
            currency: "KRW",
            payinfo: orderName,
            payeremail: this.lafia_user.email,
            payerid: this.lafia_user.user_id,
          })

          var popOption = "width=430, height=555, resizable=no, scrollbars=no, status=no;"
          var popGlOption = "width=800, height=600, resizable=no, scrollbars=no, status=no;"
          if (this.$vuetify.breakpoint.mobile) {
            this.payletterWindow = window.open(mobile_url, "", popOption)
          } else {
            this.payletterWindow = window.open(online_url, "", popGlOption)
          }

          if (!this.payletterWindow || this.payletterWindow.closed || typeof this.payletterWindow.closed == "undefined") {
            alert(this.$t("alert.error_popup_blocked"))
            return
          }

          if (this.payletterWindow) {
            this.payletterWindowEventListener = (event) => {
              if (event.data.message === "pay-completed") {
                const payloadData = event.data.data
                this.payletterEventUnregister()
                const { retCode, orderNo, status } = payloadData
                if (retCode == "0") {
                  // 성공
                  this.$router.push("/payletter/success?orderId=" + orderId + "&amount=" + this.computed_total_price)
                } else {
                  // 실패
                  this.$router.push("/fail?orderId=" + orderId + "&message=" + status)
                }
              }
            }
            window.addEventListener("message", this.payletterWindowEventListener)
          }
        } else {
          var tossPayments = TossPayments(process.env.VUE_APP_TOSS_PAYMENT_CLIENT_KEY)
          tossPayments
            .requestPayment("카드", {
              amount: this.computed_total_price,
              orderId: orderId,
              orderName: orderName,
              customerName: this.lafia_user.name,
              customerEmail: this.lafia_user.email,
              successUrl: window.location.origin + "/toss/success",
              failUrl: window.location.origin + "/fail",
              useEscrow: false,
            })
            .catch((error) => {
              console.error("토스페이먼츠 결제", error)
              // 사용자 취소, 결제실패
              this.cancelPayStatusUpdate({ order_id: orderRes.data.id, reason: "사용자취소" })
              this.pay_loading = false // 결제 진행 중 취소
            })
        }
      }
    }, 500),

    getOrderName() {
      switch (this.selectedOption) {
        case "option2":
          return this.lang === "ko" || !this.isSupportAbroadPay ? "솔로샷 듀얼" : "Solo Shot Dual"
        case "option3":
          return this.lang === "ko" || !this.isSupportAbroadPay ? "그룹샷 " + this.persons + "명" : "Group Shots" + this.persons + "people"
        case "option4":
          return this.lang === "ko" || !this.isSupportAbroadPay ? "2인할인팩" : "2-Person Discount Pack"
        case "option5":
          return this.lang === "ko" || !this.isSupportAbroadPay ? "3인할인팩" : "3-Person Discount Pack"
        case "option6":
          return this.lang === "ko" || !this.isSupportAbroadPay ? "4인할인팩" : "4-Person Discount Pack"
        default: //option1
          return this.lang === "ko" || !this.isSupportAbroadPay ? "솔로샷" : "Solo Shots"
      }
    },

    setDescription() {
      let key = "content"

      this.desc_options.content = this.default_price[key]
      if (this.lang === "en" || this.lang === "jp") {
        let langkey = key + `_${this.lang}`
        if (this.default_price[langkey]) {
          this.desc_options.content = this.default_price[langkey]
        }
      }

      if (this.promotionPriceInfo && this.promotionPriceInfo[key]) {
        this.desc_options.content = this.promotionPriceInfo[key]
        if (this.lang === "en" || this.lang === "jp") {
          let langkey = key + `_${this.lang}`
          if (this.promotionPriceInfo[langkey]) {
            this.desc_options.content = this.promotionPriceInfo[langkey]
          }
        }
      }

      this.descComponentKey++
    },

    checkPromotionDate(promotion) {
      console.log("checkPromotionDate", promotion)
      // 해당 패키지가 예약 가능 아닌지 체크가 필요함.
      const startAt = this.dayjs(promotion.startAt)
      const endAt = this.dayjs(promotion.endAt)

      if (!this.date.isBetween(startAt, endAt, "day", "[]")) {
        if (this.isFirstSkipDateWarningDialog) {
          // 해당 프로 모션의 예약 기간이 아닙니다.
          if (this.dayjs().isAfter(endAt, "day")) {
            this.showWarningDialog("dialog.promotion-warning-end-guide")
          } else {
            this.showWarningDialog(this.$t("dialog.promotion-warning-date-guide", { startAt: startAt.format("YYYY/MM/DD"), endAt: endAt.format("YYYY/MM/DD") }))
          }
        } else {
          if (this.dayjs().isBefore(startAt)) {
            this.date = startAt.startOf("day")
          } else {
            this.date = this.dayjs().startOf("day")
          }
          this.dateSelectComponentKey++
          this.isFirstSkipDateWarningDialog = true
        }
      }
    },

    showWarningDialog(message) {
      this.warning_dialog_messsage = message
      this.show_warning_dialog = true
      this.show_warning_dialog_componentKey++
    },

    confirmDialog() {
      this.show_warning_dialog = false
      if (this.warning_dialog_messsage === "dialog.promotion-warning-end-guide") {
        this.$router.replace("/")
      } else {
        const product = this.promotionPriceInfo
        if (product) {
          const startAt = this.dayjs(product.startAt).startOf("day")
          if (this.dayjs().isBefore(startAt)) {
            this.date = startAt.startOf("day")
          } else {
            this.date = this.dayjs().startOf("day")
          }
          this.dateSelectComponentKey++
        }
      }
      this.warning_dialog_messsage = ""
      this.show_warning_dialog_componentKey++
    },

    warningDialogInputEvent(e) {
      !e && this.confirmDialog()
    },

    showRefundPolicyDialog() {
      this.refund_policy_dialog_componentKey++
      this.show_refund_policy = true
    },

    agreeRefundPolicy() {
      this.show_refund_policy = false
      this.reservation()
    },

    payletterEventUnregister() {
      console.log("payletterEventUnregister")
      if (this.payletterWindowEventListener) {
        window.removeEventListener("message", this.payletterWindowEventListener)
      }
      this.payletterWindowEventListener = null
      this.payletterWindow = null
    },
  },

  beforeDestroy() {
    this.payletterEventUnregister()
  },
}
</script>
<style scoped>
.timeSelectBtn {
  border: 1px solid lightgray;
  color: #515151;
  font-size: 16px;
  font-weight: normal;
  margin: 0 3px;
  background-color: white !important;
}

.enable {
  background: #ef7fab !important;
  color: white !important;
}

.dateSelectBox {
  border-radius: 10px;
  border: 4px solid #f5e4e4;
  margin: 10px;
  padding-bottom: 20px;
}

.borderBox {
  border-radius: 10px;
  border: 4px solid #f5e4e4;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

::v-deep .conceptBtn.v-btn--disabled.v-btn--has-bg {
  cursor: not-allowed !important; /* 커서 스타일 */
  background-color: #ef7fab !important;
  color: white !important;
}

.conceptTitleBtn {
  border: 4px solid #f5e4e4 !important;
  background-color: white !important;
  color: #545454;
  border-radius: 1.5rem;
  font-size: 15px;
  font-weight: 400;
}

::v-deep .conceptTitleBtn.v-btn--disabled.v-btn--has-bg {
  cursor: not-allowed !important; /* 커서 스타일 */
  color: #545454 !important;
  background-color: white !important;
}

.stepTitle {
  border-bottom: 1px solid #c2bcba;
}

.conceptImageArea {
  border: 3px solid #f5e4e4;
  border-radius: 8px;
  width: 180px;
  height: 216px;
  cursor: pointer;
  color: #8d8d8d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conceptImageArea span {
  color: #8d8d8d;
  font-size: 14px;
}

.conceptImageArea:disabled {
  cursor: not-allowed;
}

.conceptImageAreaBorder {
  border: 3px solid #f5e4e4;
  border-radius: 8px;
}
.imageSelectable {
  cursor: pointer;
}

.imageNonSelectable {
  cursor: not-allowed;
}
</style>
