<template>
  <v-sheet v-if="isMenu" height="72" class="text-center d-flex" style="border-top: 1px solid #ddd; width: 100vw; z-index: 50">
    <!-- <v-sheet width="20%" style="cursor: pointer; padding-top: 6px" @click="$router.replace('/').catch(() => {})">
      <img height="54" :src="require(`@/assets/menus/${getMenuIcon(0, $route.path == '/')}`)" />
    </v-sheet> -->

    <v-sheet width="25%" style="cursor: pointer; padding-top: 6px" @click="$router.push('/castle').catch(() => {})">
      <img height="54" :src="require(`@/assets/menus/${getMenuIcon(0, $route.path.startsWith('/castle'))}`)" />
    </v-sheet>

    <v-sheet width="25%" style="cursor: pointer; padding-top: 6px" @click="$router.push('/eventPage/reserve_start').catch(() => {})">
      <img height="54" :src="require(`@/assets/menus/${getMenuIcon(1, $route.path.startsWith('/eventPage/reserve_start'))}`)" />
    </v-sheet>

    <v-sheet width="25%" style="cursor: pointer; padding-top: 6px" @click="$router.push('/location').catch(() => {})">
      <img height="54" :src="require(`@/assets/menus/${getMenuIcon(2, $route.path.startsWith('/location'))}`)" />
    </v-sheet>

    <v-sheet width="25%" style="cursor: pointer; padding-top: 6px" @click="goHome()">
      <img height="54" :src="require(`@/assets/menus/${getMenuIcon(3, $route.path.startsWith('/mypage'))}`)" />
    </v-sheet>
  </v-sheet>
</template>
<script>
import { isMenuPage } from "@/constant"
import { mapState } from "vuex"

export default {
  data: () => ({
    bottoms: [],
  }),

  computed: {
    ...mapState(["lang"]),

    isMenu() {
      return isMenuPage(this.$route.path)
    },
  },

  methods: {
    goHome() {
      if (this.$store.state.lafia_user.is_logined) {
        this.$router.push("/mypage/home")
      } else {
        this.$router.push("/auth/login")
      }
    },

    getMenuIcon(index, isActive) {
      let iconPath = this.getLangPath()

      switch (index) {
        // case 0:
        //   iconPath += isActive ? "main-active.png" : "main.png"
        //   break
        case 0:
          iconPath += isActive ? "guest-active.png" : "guest.png"
          break
        case 1:
          iconPath += isActive ? "reserve-active.png" : "reserve.png"
          break
        case 2:
          iconPath += isActive ? "map-active.png" : "map.png"
          break
        case 3:
          iconPath += isActive ? "mypage-active.png" : "mypage.png"
          break

        // case 3:
        //   iconPath += isActive ? "makeup-active.png" : "makeup.png"
        //   break

        default:
          break
      }
      return iconPath
    },

    getLangPath() {
      //const supportLang = ["ko", "en", "jp"]
      switch (this.lang) {
        case "ko":
          return ""
        case "jp":
          return "jp/"
        default:
          return "en/"
      }
    },
  },
}
</script>
